.concept {
  position: relative;

  @include respond-to("small") {
    display: flex;
    align-items: stretch;
    min-height: 28rem;

    &:nth-child(even) {
      flex-direction: row-reverse;
    }
  }

  &:not(:last-of-type) {
    margin-bottom: $marginDefault;
  }

  &:hover &__image {
    background-size: 120%;
    @include respond-to("small") {
      background-size: cover;
    }
  }

  &:hover &__title {
    &::after {
      width: 5rem;
    }
  }

  &--active &__image {
    transform: rotateY(180deg);
    z-index: 5;
  }

  &--active &__content {
    transform: rotateY(360deg);
    z-index: 10;
  }

  &__image {
    transition: 500ms ease-out;
    position: absolute;
    width: 100%;
    top: 0;
    height: 100%;
    background-size: 100%;
    background-position: center;
    transform: rotateY(0deg);
    z-index: 10;
    @include respond-to("small") {
      height: auto;
      position: relative;
      width: 300px;
      background-size: cover;
    }
  }

  &__gradient {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    @include respond-to("small") {
      display: none;
    }
  }

  &__title {
    margin: 0;
    position: relative;
    @include center;
    &::after {
      @include pseudo(absolute);
      height: 3px;
      width: 0rem;
      background-color: #fff;
      bottom: -0.5rem;
      left: 50%;
      transform: translateX(-50%);
      transition: 200ms ease-out;
    }
  }

  &__content {
    text-align: justify;
    transition: 500ms ease-out;
    padding: $paddingDefault;
    transform: rotateY(180deg);
    z-index: 5;
    background-color: $colorPrimary;
    position: relative;
    @include respond-to("small") {
      transform: rotateY(0);
      flex: 1;
    }
  }
}
