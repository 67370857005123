.reference {
  width: 100%;
  position: relative;
  overflow: hidden;

  @include respond-to("small") {
    max-width: calc(50% - #{$marginDefault} / 2);
    float: left;
    &:nth-child(odd) {
      margin-right: $marginDefault;
    }

    &--open &__content,
    &:hover &__content {
      transform: translateY(0);
      opacity: 1;
    }
  }

  &:not(:last-of-type) {
    margin-bottom: $marginDefault;
  }

  &__header {
    height: calc(100vw / 16 * 9);
    background-position: center;
    background-size: cover;
    position: relative;
    @include respond-to("small") {
      max-height: 30rem;
    }
  }

  &__gradient {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &__title {
    width: calc(100% - #{$marginMedium});
    position: absolute;
    margin: 0;
    @include center;
  }

  &__content {
    padding: $paddingDefault;
    background-color: $colorPrimary;
    text-align: justify;

    @include respond-to("small") {
      position: absolute;
      top: 0;
      height: 100%;
      opacity: 0;
      transform: translateY(100%);
      transition: 500ms ease-out;
    }

    a {
      border: 1px solid $colorTextLight;
      color: $colorTextLight;
      font-family: "Josephine Sans", sans-serif;
      padding: $marginSmall;
      margin-top: $marginSmall;
      display: inline-block;
    }
  }

  &__text {
    p:not(:first-child) {
      margin-top: $marginSmall;
    }
  }
}
