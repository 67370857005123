.page-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $colorSecondary;
  z-index: 1001;
  opacity: 1;
  transition: 200ms ease-out;
  &::after {
    @include pseudo(absolute);
    @include center;
    border: 1rem solid transparent;
    border-radius: 50%;
    border-top: 1rem solid $colorPrimary;
    width: 10rem;
    height: 10rem;
    animation: spin 2s linear infinite;
  }

  &--loaded {
    transform: translateY(-5rem);
    opacity: 0;
  }

  &__logo {
    margin: 30vh auto;
    display: block;
  }
}
