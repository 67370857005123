.footer {
  background-color: $colorSecondary;
  width: 100%;
  padding: $paddingDefault;
  text-align: center;
  position: relative;
  z-index: 50;

  @include respond-to("small") {
    text-align: left;
  }

  &__title {
    color: $colorPrimary;
    font-size: 1.4rem;
    margin-bottom: $marginDefault;
  }

  &__text {
    color: $colorLight;
    font-size: 2.8rem;
    margin-bottom: $marginMedium;

    &--small {
      font-size: 1.6rem;
    }
  }

  &__link {
    color: $colorTextLight;
    font-size: 1.6rem;
    transition: 200ms ease-out;
    &:hover {
      color: $colorPrimary;
    }
  }

  &__button {
    appearance: none;
    color: $colorPrimary;
    border: 1px solid $colorPrimary;
    padding: 1rem 2rem;
    background-color: transparent;
    cursor: pointer;
    position: relative;
  }

  &__social-icons {
    margin-bottom: $marginMedium;
  }

  &__social-icon {
    width: 3.5rem;
    &:not(:last-child) {
      margin-right: $marginDefault;
    }
  }

  &__input {
    appearance: none;
    width: 100%;
    padding: 1rem 2rem;
    background-color: $colorLight;
    border: none;
    font-family: "Josefin Sans", sans-serif;

    &:not(:last-child) {
      margin-bottom: $marginDefault;
    }

    &--textarea {
      appearance: none;
      height: 12rem;
      resize: vertical;
    }
  }

  &__contact-form {
    margin-bottom: $marginMedium;
    position: relative;
    flex-grow: 1;
    @include respond-to("small") {
      margin-right: 5rem;
    }

    &--loading {
      &::before {
        @include pseudo(absolute);
        @include center;
        border: 1rem solid transparent;
        border-radius: 50%;
        border-top: 1rem solid $colorPrimary;
        width: 10rem;
        height: 10rem;
        animation: spin 2s linear infinite;
      }
    }
  }

  &__copyright {
    margin-top: $marginMedium;
    @include respond-to("small") {
      margin-top: 0;
      flex-grow: 1;
      text-align: left;
    }
  }

  &__container {
    @include respond-to("small") {
      text-align: right;
    }
  }

  &__column-1 {
    max-width: 102.4rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    @include respond-to("small") {
      flex-direction: row-reverse;
    }
  }

  &__column-2 {
    max-width: 102.4rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    @include respond-to("small") {
      flex-direction: row-reverse;
    }
  }
}
