body {
  background-color: $colorBackground;
  color: $colorSecondary;
  margin-top: $heightNavbar;
  @include respond-to("small") {
    margin-top: 0;
  }
}

main {
  padding-top: $marginMedium;
  padding-bottom: $marginMedium;
  background-color: $colorBackground;
  z-index: 10;
  position: relative;
}

.grecaptcha-badge {
  display: none !important;
}