.feedback {
  &:not(:last-of-type) {
    margin-bottom: calc(#{$marginDefault} + 3rem);
  }
  
  @include respond-to("small") {
    float: left;
    max-width: calc(50% - #{$marginDefault} / 2);

    &:not(:last-of-type) {
      margin-bottom: 0;
      margin-right: $marginDefault;
    }
  }

  &__content {
    padding: $paddingDefault;
    padding-top: calc(#{$paddingDefault} + 2rem);
    background-color: $colorPrimary;
    position: relative;
    &::before {
      @include pseudo(absolute);
      top: -3rem;
      right: 1rem;
      background-image: url("#{$pathImage}/quote.svg");
      width: 8rem;
      height: 6rem;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &__person {
    background-color: $colorSecondary;
    padding: $paddingSmall;
    display: flex;
  }

  &__picture {
    border-radius: 50%;
    width: 6rem;
    height: 6rem;
    background-position: center;
    background-size: cover;
  }

  &__name {
    color: $colorLight;
    flex-grow: 1;
    position: relative;
  }

  &__name-container {
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
  }
}
