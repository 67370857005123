/*ABSTRACTS*/
/*BASE*/
@-webkit-keyframes spin {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg); }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg); } }
@keyframes spin {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg); }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg); } }

@-webkit-keyframes jelly {
  from {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); }
  30% {
    -webkit-transform: scale(1.25, 0.75);
            transform: scale(1.25, 0.75); }
  40% {
    -webkit-transform: scale(0.75, 1.25);
            transform: scale(0.75, 1.25); }
  50% {
    -webkit-transform: scale(1.15, 0.85);
            transform: scale(1.15, 0.85); }
  65% {
    -webkit-transform: scale(0.95, 1.05);
            transform: scale(0.95, 1.05); }
  75% {
    -webkit-transform: scale(1.05, 0.95);
            transform: scale(1.05, 0.95); }
  to {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); } }

@keyframes jelly {
  from {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); }
  30% {
    -webkit-transform: scale(1.25, 0.75);
            transform: scale(1.25, 0.75); }
  40% {
    -webkit-transform: scale(0.75, 1.25);
            transform: scale(0.75, 1.25); }
  50% {
    -webkit-transform: scale(1.15, 0.85);
            transform: scale(1.15, 0.85); }
  65% {
    -webkit-transform: scale(0.95, 1.05);
            transform: scale(0.95, 1.05); }
  75% {
    -webkit-transform: scale(1.05, 0.95);
            transform: scale(1.05, 0.95); }
  to {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); } }

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-weight: 300;
  outline: none; }

a {
  text-decoration: none; }

html {
  font-size: 62.5%; }

body {
  box-sizing: border-box; }

body {
  background-color: #f3efe9;
  color: #2a363b;
  margin-top: 7.5rem; }
  @media (min-width: 600px) {
    body {
      margin-top: 0; } }

main {
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: #f3efe9;
  z-index: 10;
  position: relative; }

.grecaptcha-badge {
  display: none !important; }

body {
  font-family: "Noto Serif", serif;
  font-size: 1.4rem; }

.heading-tertiary,
.heading-quaternary {
  font-family: "Josefin Sans", sans-serif; }

.heading-primary {
  color: #c8bca0;
  font-size: 2.4rem;
  margin-top: 4rem;
  line-height: 3.2rem;
  text-align: center;
  font-family: "Josefin Sans", sans-serif; }
  @media (min-width: 600px) {
    .heading-primary {
      font-size: 4rem;
      line-height: 4.5rem !important; } }

.heading-secondary {
  color: #2a363b;
  margin-top: 4rem;
  margin-bottom: 2rem;
  font-size: 2.4rem;
  text-align: center;
  font-family: "Josefin Sans", sans-serif; }
  .heading-secondary--light {
    color: #ffffff; }
  .heading-secondary--lightbox {
    text-align: left;
    margin-top: 2rem;
    margin-bottom: 1rem !important; }
    .heading-secondary--lightbox:first-of-type {
      margin-top: 0 !important; }
  .heading-secondary--desktop {
    display: none; }
    @media (min-width: 600px) {
      .heading-secondary--desktop {
        display: block; } }

.paragraph-section {
  color: #2a363b;
  margin-bottom: 4rem;
  text-align: center; }

.paragraph-article {
  font-size: 1.6rem; }

.u-margin-top-none {
  margin-top: 0; }

/*COMPONENTS*/
.burger {
  position: absolute;
  right: 2rem;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer; }
  @media (min-width: 600px) {
    .burger {
      display: none; } }
  .burger--open .burger__line--1 {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    top: 1rem; }
  .burger--open .burger__line--2 {
    -webkit-transform: translateX(-2rem);
            transform: translateX(-2rem);
    opacity: 0; }
  .burger--open .burger__line--3 {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    bottom: 1rem; }
  .burger__line {
    position: relative;
    width: 3.5rem;
    height: 0.5rem;
    background-color: #c8bca0;
    -webkit-transition: 200ms ease-out;
    transition: 200ms ease-out; }
    .burger__line:not(:last-child) {
      margin-bottom: 0.5rem; }
    .burger__line--1 {
      top: 0; }
    .burger__line--3 {
      bottom: 0; }

.concept {
  position: relative; }
  @media (min-width: 600px) {
    .concept {
      display: -webkit-box;
      display: flex;
      -webkit-box-align: stretch;
              align-items: stretch;
      min-height: 28rem; }
      .concept:nth-child(even) {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
                flex-direction: row-reverse; } }
  .concept:not(:last-of-type) {
    margin-bottom: 2rem; }
  .concept:hover .concept__image {
    background-size: 120%; }
    @media (min-width: 600px) {
      .concept:hover .concept__image {
        background-size: cover; } }
  .concept:hover .concept__title::after {
    width: 5rem; }
  .concept--active .concept__image {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
    z-index: 5; }
  .concept--active .concept__content {
    -webkit-transform: rotateY(360deg);
            transform: rotateY(360deg);
    z-index: 10; }
  .concept__image {
    -webkit-transition: 500ms ease-out;
    transition: 500ms ease-out;
    position: absolute;
    width: 100%;
    top: 0;
    height: 100%;
    background-size: 100%;
    background-position: center;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
    z-index: 10; }
    @media (min-width: 600px) {
      .concept__image {
        height: auto;
        position: relative;
        width: 300px;
        background-size: cover; } }
  .concept__gradient {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); }
    @media (min-width: 600px) {
      .concept__gradient {
        display: none; } }
  .concept__title {
    margin: 0;
    position: relative;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    .concept__title::after {
      content: " ";
      display: block;
      position: absolute;
      height: 3px;
      width: 0rem;
      background-color: #fff;
      bottom: -0.5rem;
      left: 50%;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      -webkit-transition: 200ms ease-out;
      transition: 200ms ease-out; }
  .concept__content {
    text-align: justify;
    -webkit-transition: 500ms ease-out;
    transition: 500ms ease-out;
    padding: 2rem;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
    z-index: 5;
    background-color: #c8bca0;
    position: relative; }
    @media (min-width: 600px) {
      .concept__content {
        -webkit-transform: rotateY(0);
                transform: rotateY(0);
        -webkit-box-flex: 1;
                flex: 1; } }

.feedback:not(:last-of-type) {
  margin-bottom: calc(2rem + 3rem); }

@media (min-width: 600px) {
  .feedback {
    float: left;
    max-width: calc(50% - 2rem / 2); }
    .feedback:not(:last-of-type) {
      margin-bottom: 0;
      margin-right: 2rem; } }

.feedback__content {
  padding: 2rem;
  padding-top: calc(2rem + 2rem);
  background-color: #c8bca0;
  position: relative; }
  .feedback__content::before {
    content: " ";
    display: block;
    position: absolute;
    top: -3rem;
    right: 1rem;
    background-image: url("/templates/web/img//quote.svg");
    width: 8rem;
    height: 6rem;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat; }

.feedback__person {
  background-color: #2a363b;
  padding: 1rem;
  display: -webkit-box;
  display: flex; }

.feedback__picture {
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  background-position: center;
  background-size: cover; }

.feedback__name {
  color: #ffffff;
  -webkit-box-flex: 1;
          flex-grow: 1;
  position: relative; }

.feedback__name-container {
  position: absolute;
  top: 50%;
  left: 1rem;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.reference {
  width: 100%;
  position: relative;
  overflow: hidden; }
  @media (min-width: 600px) {
    .reference {
      max-width: calc(50% - 2rem / 2);
      float: left; }
      .reference:nth-child(odd) {
        margin-right: 2rem; }
      .reference--open .reference__content,
      .reference:hover .reference__content {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1; } }
  .reference:not(:last-of-type) {
    margin-bottom: 2rem; }
  .reference__header {
    height: calc(100vw / 16 * 9);
    background-position: center;
    background-size: cover;
    position: relative; }
    @media (min-width: 600px) {
      .reference__header {
        max-height: 30rem; } }
  .reference__gradient {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); }
  .reference__title {
    width: calc(100% - 4rem);
    position: absolute;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .reference__content {
    padding: 2rem;
    background-color: #c8bca0;
    text-align: justify; }
    @media (min-width: 600px) {
      .reference__content {
        position: absolute;
        top: 0;
        height: 100%;
        opacity: 0;
        -webkit-transform: translateY(100%);
                transform: translateY(100%);
        -webkit-transition: 500ms ease-out;
        transition: 500ms ease-out; } }
    .reference__content a {
      border: 1px solid #5b5f5f;
      color: #5b5f5f;
      font-family: "Josephine Sans", sans-serif;
      padding: 1rem;
      margin-top: 1rem;
      display: inline-block; }
  .reference__text p:not(:first-child) {
    margin-top: 1rem; }

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
  display: none;
  overflow: auto; }
  .lightbox--open {
    display: block; }
  .lightbox--open .lightbox__inner {
    -webkit-animation: jelly 500ms ease;
            animation: jelly 500ms ease; }
  .lightbox__inner {
    width: 90vw;
    max-width: 1000px;
    background-color: #ffffff;
    margin: 5vh auto;
    box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.2);
    position: relative; }
  .lightbox__close-handler {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 30px;
    height: 30px;
    cursor: pointer; }
    .lightbox__close-handler::after, .lightbox__close-handler::before {
      content: " ";
      display: block;
      position: absolute;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      top: 15px;
      left: 15px;
      width: 20px;
      height: 2px;
      background-color: #ffffff; }
    .lightbox__close-handler::after {
      -webkit-transform: translate(-50%, -50%) rotate(45deg);
              transform: translate(-50%, -50%) rotate(45deg); }
    .lightbox__close-handler::before {
      -webkit-transform: translate(-50%, -50%) rotate(-45deg);
              transform: translate(-50%, -50%) rotate(-45deg); }
  .lightbox__title {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    font-family: "Josefin Sans", sans-serif;
    color: #ffffff;
    font-weight: bold;
    padding: 10px;
    background-color: #c8bca0;
    height: 38px;
    font-size: 1.6rem; }
  .lightbox__data {
    padding: 20px; }
    .lightbox__data h2 {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 15px; }
    .lightbox__data button {
      margin-top: 10px;
      -webkit-transition: 200ms ease-out;
      transition: 200ms ease-out;
      border: none;
      background-color: #c8bca0;
      color: #ffffff;
      cursor: pointer;
      padding: 5px 20px;
      font-size: 17px;
      border-radius: 5px; }
      .lightbox__data button:not(:last-of-type) {
        margin-right: 10px; }
      .lightbox__data button:hover {
        background-color: #edeae1; }

/*LAYOUT*/
.footer {
  background-color: #2a363b;
  width: 100%;
  padding: 2rem;
  text-align: center;
  position: relative;
  z-index: 50; }
  @media (min-width: 600px) {
    .footer {
      text-align: left; } }
  .footer__title {
    color: #c8bca0;
    font-size: 1.4rem;
    margin-bottom: 2rem; }
  .footer__text {
    color: #ffffff;
    font-size: 2.8rem;
    margin-bottom: 4rem; }
    .footer__text--small {
      font-size: 1.6rem; }
  .footer__link {
    color: #5b5f5f;
    font-size: 1.6rem;
    -webkit-transition: 200ms ease-out;
    transition: 200ms ease-out; }
    .footer__link:hover {
      color: #c8bca0; }
  .footer__button {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    color: #c8bca0;
    border: 1px solid #c8bca0;
    padding: 1rem 2rem;
    background-color: transparent;
    cursor: pointer;
    position: relative; }
  .footer__social-icons {
    margin-bottom: 4rem; }
  .footer__social-icon {
    width: 3.5rem; }
    .footer__social-icon:not(:last-child) {
      margin-right: 2rem; }
  .footer__input {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    width: 100%;
    padding: 1rem 2rem;
    background-color: #ffffff;
    border: none;
    font-family: "Josefin Sans", sans-serif; }
    .footer__input:not(:last-child) {
      margin-bottom: 2rem; }
    .footer__input--textarea {
      -webkit-appearance: none;
         -moz-appearance: none;
              appearance: none;
      height: 12rem;
      resize: vertical; }
  .footer__contact-form {
    margin-bottom: 4rem;
    position: relative;
    -webkit-box-flex: 1;
            flex-grow: 1; }
    @media (min-width: 600px) {
      .footer__contact-form {
        margin-right: 5rem; } }
    .footer__contact-form--loading::before {
      content: " ";
      display: block;
      position: absolute;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      border: 1rem solid transparent;
      border-radius: 50%;
      border-top: 1rem solid #c8bca0;
      width: 10rem;
      height: 10rem;
      -webkit-animation: spin 2s linear infinite;
              animation: spin 2s linear infinite; }
  .footer__copyright {
    margin-top: 4rem; }
    @media (min-width: 600px) {
      .footer__copyright {
        margin-top: 0;
        -webkit-box-flex: 1;
                flex-grow: 1;
        text-align: left; } }
  @media (min-width: 600px) {
    .footer__container {
      text-align: right; } }
  .footer__column-1 {
    max-width: 102.4rem;
    margin: 0 auto;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column; }
    @media (min-width: 600px) {
      .footer__column-1 {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
                flex-direction: row-reverse; } }
  .footer__column-2 {
    max-width: 102.4rem;
    margin: 0 auto;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column; }
    @media (min-width: 600px) {
      .footer__column-2 {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
                flex-direction: row-reverse; } }

.header {
  background-image: url("/templates/web/img/header_bg.jpg");
  background-size: cover;
  background-position: center;
  height: calc(100vh - 7.5rem);
  text-align: center; }
  .header__inner {
    position: fixed;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    max-width: 64rem;
    width: calc(100% - 4rem);
    top: 15rem; }
    @media (min-width: 600px) {
      .header__inner {
        top: 0; } }
  @media (min-width: 600px) {
    .header {
      margin-bottom: 7.5rem; } }
  .header__gradient {
    background-color: rgba(42, 54, 59, 0.75);
    width: 100%;
    height: 100%; }
  .header__scroll-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #2a363b;
    opacity: 0;
    z-index: 1;
    pointer-events: none; }
  .header__logo {
    width: 15rem;
    margin-top: 4rem; }
    @media (min-width: 600px) {
      .header__logo {
        margin-top: 30vh; } }
  .header__arrow {
    width: 3rem;
    position: absolute;
    bottom: 4rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    cursor: pointer;
    z-index: 10; }
    @media (min-width: 600px) {
      .header__arrow {
        display: none; } }

.navbar {
  background-color: #2a363b;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 7.5rem;
  z-index: 999; }
  @media (min-width: 600px) {
    .navbar {
      position: absolute;
      top: auto;
      bottom: 0; }
      .navbar--fixed {
        position: fixed;
        top: 0;
        bottom: auto; }
      .navbar--fixed .navigation__nav-icon-container {
        width: 4rem;
        margin-right: 4rem; } }
  .navbar__logo {
    width: 3.2rem;
    position: absolute;
    top: 50%;
    left: 2rem;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
    @media (min-width: 600px) {
      .navbar__logo {
        display: none; } }

.navigation {
  height: calc(100vh - 7.5rem);
  width: 100%;
  position: fixed;
  top: 7.5rem;
  left: 0;
  -webkit-transition: 200ms ease-out;
  transition: 200ms ease-out;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  background-image: url("/templates/web/img/nav_bg.jpg");
  background-position: center;
  background-size: cover; }
  @media (min-width: 600px) {
    .navigation {
      height: 100%;
      position: absolute;
      background-color: #2a363b;
      background-image: none; } }
  .navigation__background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #2a363b; }
    @media (min-width: 600px) {
      .navigation__background {
        display: none; } }
  .navigation__gradient {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: rgba(42, 54, 59, 0.8); }
  .navigation--open {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  .navigation__links {
    list-style: none;
    margin-top: 4rem;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: center;
            justify-content: center; }
    @media (min-width: 600px) {
      .navigation__links {
        margin-top: 0;
        text-align: center;
        position: absolute;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
                flex-direction: row;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        width: calc(100% - 4rem); } }
  .navigation__link {
    color: #c8bca0;
    text-align: center;
    font-size: 2.2rem;
    font-family: "Josefin Sans", sans-serif; }
    .navigation__link:not(:last-child) {
      margin-bottom: 4rem; }
    .navigation__link:hover::after {
      width: 100%; }
    @media (min-width: 600px) {
      .navigation__link {
        margin-bottom: 0 !important;
        position: relative; }
        .navigation__link:not(:last-child) {
          margin-right: 4rem; }
        .navigation__link::after {
          content: " ";
          display: block;
          position: absolute;
          left: 50%;
          bottom: -1rem;
          width: 0;
          height: 1px;
          background-color: #c8bca0;
          -webkit-transform: translateX(-50%);
                  transform: translateX(-50%);
          -webkit-transition: 200ms ease-out;
          transition: 200ms ease-out; } }
  .navigation__nav-icon-container {
    width: 0;
    overflow: hidden;
    margin-right: 0;
    -webkit-transition: 400ms ease-out;
    transition: 400ms ease-out; }
  .navigation__nav-icon {
    width: 4rem; }

.page-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #2a363b;
  z-index: 1001;
  opacity: 1;
  -webkit-transition: 200ms ease-out;
  transition: 200ms ease-out; }
  .page-loader::after {
    content: " ";
    display: block;
    position: absolute;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    border: 1rem solid transparent;
    border-radius: 50%;
    border-top: 1rem solid #c8bca0;
    width: 10rem;
    height: 10rem;
    -webkit-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite; }
  .page-loader--loaded {
    -webkit-transform: translateY(-5rem);
            transform: translateY(-5rem);
    opacity: 0; }
  .page-loader__logo {
    margin: 30vh auto;
    display: block; }

.section {
  margin: auto;
  max-width: calc(100vw - 2rem * 2); }
  .section--concept {
    max-width: 102.4rem; }
  .section--references {
    max-width: 100rem;
    padding: 0 2rem; }
    .section--references::after {
      content: " ";
      clear: both;
      display: table; }
  .section--feedback {
    max-width: 96rem;
    padding: 0 2rem; }
    .section--feedback::after {
      content: " ";
      clear: both;
      display: table; }

/*PAGES*/
