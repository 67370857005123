.navbar {
  background-color: $colorSecondary;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $heightNavbar;
  z-index: 999;

  @include respond-to("small") {
    position: absolute;
    top: auto;
    bottom: 0;
    &--fixed {
      position: fixed;
      top: 0;
      bottom: auto;
    }

    &--fixed .navigation__nav-icon-container{
      width: 4rem;
      margin-right: 4rem;
    }
  }

  &__logo {
    width: 3.2rem;
    position: absolute;
    top: 50%;
    left: $marginDefault;
    transform: translateY(-50%);
    @include respond-to("small") {
      display: none;
    }
  }
}
