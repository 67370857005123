.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba($colorDark, 0.4);
  z-index: 1000;
  display: none;
  overflow: auto;

  &--open {
    display: block;
  }

  &--open &__inner {
    animation: jelly 500ms ease;
  }

  &__inner {
    width: 90vw;
    max-width: 1000px;
    background-color: $colorLight;
    margin: 5vh auto;
    box-shadow: 0 0 10px 10px rgba($colorDark, 0.2);
    position: relative;
  }

  &__close-handler {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    &::after,
    &::before {
      @include pseudo(absolute);
      @include center;
      top: 15px;
      left: 15px;
      width: 20px;
      height: 2px;
      background-color: $colorLight;
    }

    &::after {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  &__title {
    @include preventSelect;
    font-family: "Josefin Sans", sans-serif;
    color: $colorLight;
    font-weight: bold;
    padding: 10px;
    background-color: $colorPrimary;
    height: 38px;
    font-size: 1.6rem;
  }

  &__data {
    padding: 20px;

    h2 {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 15px;
    }

    button {
      margin-top: 10px;
      transition: 200ms ease-out;
      border: none;
      background-color: $colorPrimary;
      color: $colorLight;
      cursor: pointer;
      padding: 5px 20px;
      font-size: 17px;
      border-radius: 5px;
      &:not(:last-of-type) {
        margin-right: 10px;
      }

      &:hover {
        background-color: lighten($colorPrimary, 20);
      }
    }
  }
}
