.section{
  margin: auto;
  max-width: calc(100vw - #{$marginDefault} * 2);

  &--concept{
    max-width: 102.4rem;
  }

  &--references{
    @include clearfix;
    max-width: 100rem;
    padding: 0 2rem;
  }
  
  &--feedback{
    max-width: 96rem;
    padding: 0 2rem;
    @include clearfix;
  }
}