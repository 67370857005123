.burger {
  position: absolute;
  right: 2rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;

  @include respond-to("small") {
    display: none;
  }

  &--open &__line {
    &--1 {
      transform: rotate(45deg);
      top: 1rem;
    }

    &--2 {
      transform: translateX(-2rem);
      opacity: 0;
    }

    &--3 {
      transform: rotate(-45deg);
      bottom: 1rem;
    }
  }

  &__line {
    position: relative;
    width: 3.5rem;
    height: 0.5rem;
    background-color: $colorPrimary;
    transition: 200ms ease-out;
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    &--1 {
      top: 0;
    }

    &--3 {
      bottom: 0;
    }
  }
}
