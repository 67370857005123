.navigation {
  height: calc(100vh - #{$heightNavbar});
  width: 100%;
  position: fixed;
  top: $heightNavbar;
  left: 0;
  transition: 200ms ease-out;
  transform: translateY(-100%);
  background-image: url("#{$pathImage}nav_bg.jpg");
  background-position: center;
  background-size: cover;

  @include respond-to("small") {
    height: 100%;
    position: absolute;
    background-color: $colorSecondary;
    background-image: none;
  }

  &__background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $colorSecondary;
    @include respond-to("small") {
      display: none;
    }
  }

  &__gradient {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: rgba($colorSecondary, 0.8);
  }

  &--open {
    transform: translateY(0);
  }

  &__links {
    list-style: none;
    margin-top: $marginMedium;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include respond-to("small") {
      margin-top: 0;
      text-align: center;
      position: absolute;
      flex-direction: row;
      @include center;
      width: calc(100% - #{$marginMedium});
    }
  }

  &__link {
    color: $colorPrimary;
    text-align: center;
    font-size: 2.2rem;
    font-family: "Josefin Sans", sans-serif;
    &:not(:last-child) {
      margin-bottom: $marginMedium;
    }

    &:hover {
      &::after {
        width: 100%;
      }
    }

    @include respond-to("small") {
      margin-bottom: 0 !important;
      position: relative;

      &:not(:last-child) {
        margin-right: $marginMedium;
      }

      &::after {
        @include pseudo(absolute);
        left: 50%;
        bottom: -1rem;
        width: 0;
        height: 1px;
        background-color: $colorPrimary;
        transform: translateX(-50%);
        transition: 200ms ease-out;
      }
    }
  }

  &__nav-icon-container {
    width: 0;
    overflow: hidden;
    margin-right: 0;
    transition: 400ms ease-out;
  }

  &__nav-icon {
    width: 4rem;
  }
}
