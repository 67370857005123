*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
	font-weight: 300;
	outline: none;
}

a{
	text-decoration: none;
}

html{
    font-size: 62.5%;
}

body{
    box-sizing: border-box; 
}