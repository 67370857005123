.header {
  background-image: url($pathImage+"header_bg.jpg");
  background-size: cover;
  background-position: center;
  height: calc(100vh - #{$heightNavbar});
  text-align: center;

  &__inner {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    max-width: 64rem;
    width: calc(100% - 4rem);
    top: 15rem;
    @include respond-to("small") {
      top: 0;
    }
  }

  @include respond-to("small") {
    margin-bottom: $heightNavbar;
  }

  &__gradient {
    background-color: rgba($colorSecondary, 0.75);
    width: 100%;
    height: 100%;
  }

  &__scroll-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $colorSecondary;
    opacity: 0;
    z-index: 1;
    pointer-events: none;
  }

  &__logo {
    width: 15rem;
    margin-top: $marginMedium;
    @include respond-to("small") {
      margin-top: 30vh;
    }
  }

  &__arrow {
    width: 3rem;
    position: absolute;
    bottom: $marginMedium;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    z-index: 10;
    @include respond-to("small") {
      display: none;
    }
  }
}
