body {
  font-family: "Noto Serif", serif;
  font-size: 1.4rem;
}

.heading-tertiary,
.heading-quaternary {
  font-family: "Josefin Sans", sans-serif;
}

.heading-primary {
  color: $colorPrimary;
  font-size: $fontSizeTitle;
  margin-top: $marginMedium;
  line-height: $lineHeightTitle;
  text-align: center;
  font-family: "Josefin Sans", sans-serif;

  @include respond-to("small") {
    font-size: $fontSizeTitleLarge;
    line-height: 4.5rem !important;
  }
}

.heading-secondary {
  color: $colorSecondary;
  margin-top: $marginMedium;
  margin-bottom: $marginDefault;
  font-size: $fontSizeArticleTitle;
  text-align: center;
  font-family: "Josefin Sans", sans-serif;

  &--light {
    color: $colorLight;
  }

  &--lightbox {
    text-align: left;
    margin-top: 2rem;
    margin-bottom: 1rem !important;
    &:first-of-type {
      margin-top: 0 !important;
    }
  }

  &--desktop {
    display: none;
    @include respond-to("small") {
      display: block;
    }
  }
}

.paragraph-section {
  color: $colorSecondary;
  margin-bottom: $marginMedium;
  text-align: center;
}

.paragraph-article {
  font-size: $fontSizeSectionText;
  $color: $colorSecondary;
}
